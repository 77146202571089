import React, { Component } from "react";

class ProductLargeImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bgImage: "url(" + this.props.backgroundImage + ")"
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      bgImage: "url(" + nextProps.backgroundImage + ")"
    });
  }

  render() {
    return (
      <div>
        {this.props.imageType === "cw" && (
          <div className="product-swatches right">
            {this.props.isSignatureNow && 
                      <div className="product-notify-badge">
                        <div style={{height: '80px', width: '50px'}}></div>
                        <span>NOW</span>
                      </div>
                    }
            {this.props.isStacyGarcia && 
                      <div className="sg-product-notify-badge">
                        <div style={{height: '50px', width: '50px'}}></div>
                        <span><img src="/wp-content/uploads/2025/03/SG-Web-logo.png" style={{ width: '50px', height: '50px' }} /></span>
                      </div>
                    } 
            <img
              src={this.props.backgroundImage}
              height="500"
              width="500"
              alt="Large Sample"
            />
            {this.props.productDesignID !== 0 && (
              <div
                className="customize-button-div"
                style={{
                  textAlign: "center",
                  position: "relative",
                  left: "-125px",
                  top: "375px",
                  height: "37px",
                  width: "120px"
                }}
              >
                <a
                  href={
                    `https://spark.signatureflooring.com/products/open/?ID=` +
                  this.props.productDesignID + `&simID=` + this.props.sparkID
                  }
                  target="_new"
                  className="customize-button"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    textAlign: "center",
                    paddingLeft: "25px",
                    paddingRight: "25px"
                  }}
                >
                  Customize
                </a>
                
              </div>
            )}

              
          </div>
        )}
        {this.props.imageType === "rs" && (
          <div
            className="product-swatches right"
            style={{ backgroundSize: "500px 500px" }}
          >
            <img
              src={this.props.backgroundImage}
              height="500"
              width="500"
              alt="Large Sample"
            />
          </div>
        )}

        {this.props.imageType === "coord" && (
          <div
            className="product-swatches right"
            style={{ backgroundSize: "500px 500px" }}
          >
            <img
              src={this.props.backgroundImage}
              height="500"
              width="500"
              alt="Large Sample"
            />
            {this.props.coordinates_customize_id !== '' && (
              <div
                className="customize-button-div"
                style={{
                  textAlign: "center",
                  position: "relative",
                  left: "-125px",
                  top: "375px",
                  height: "37px",
                  width: "120px"
                }}
              >
                <a
                  href={
                    `https://spark.signatureflooring.com/products/open/?ID=` +
                  this.props.coordinates_customize_id + `&simID=` + this.props.coordinates_customize_sim_id
                  }
                  target="_new"
                  className="customize-button"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    textAlign: "center",
                    paddingLeft: "25px",
                    paddingRight: "25px"
                  }}
                >
                  Customize
                </a>
                
              </div>
            )}

            
          </div>
        )}
      </div>
    );
  }
}

export default ProductLargeImage;
